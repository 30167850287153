<template>
  <section
    id="slide-first"
    class="secondary py-10"
  >
    <br>
    <base-subheading
      align="center"
      title="Products Listing"
      class="text-uppercase"
      space="2"
      style="color:white;"
    />
    <br>
    <v-sheet
      class="mx-auto secondary"
      dark
    >
      <v-slide-group
        class="pa-4"
      >
        <v-slide-item
          v-for="(model, n) in models"
          :key="n"
          v-slot="{ active, toggle }"
          class="align-self-center"
        >
          <v-card
            :color="active ? 'accent' : 'white lighten-1'"
            class="ma-4"
            height="200"
            width="250"
            @click="toggle"
          >
            <base-img
              :src="model.src"
              height="130"
              width="250"
            />
            <v-card-text
              class="text-center"
              :style="active ? 'color:#e8f1f2;font-weight: bold;font-family: Work Sans;' : 'color:#13293d;font-weight: bold;font-family: Work Sans'"
            >
              {{ model.title }}
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
  </section>
</template>

<script>
  export default {
    name: 'SectionSlideFirst',

    data: () => ({
      models: [
        {
          title: 'Activated Carbon (coconut shell)',
          src: '',
        },
        {
          title: 'Antioxidant',
          src: '',
        },
        {
          title: 'Antitack Powder Free',
          src: '',
        },
        {
          title: 'Antifoam',
          src: '',
        },
        {
          title: 'Acrylic Polymer Binder',
          src: '',
        },
        {
          title: 'Biocides',
          src: '',
        },
        {
          title: 'Coagulant',
          src: '',
        },
        {
          title: 'Dispersing Agents',
          src: '',
        },
        {
          title: 'Fragrance/Flavour',
          src: '',
        },
        {
          title: 'Formic Acid',
          src: '',
        },
        {
          title: 'Nitrile Butadiene Latex',
          src: '',
        },
        {
          title: 'PVC Resin Paste',
          src: '',
        },
        {
          title: 'Polyurethane Wet Donning',
          src: '',
        },
        {
          title: 'Polyurethane Polymer',
          src: '',
        },
        {
          title: 'Titanium Dioxide',
          src: '',
        },
        {
          title: 'Wetting Agents',
          src: '',
        },
        {
          title: 'Wetting Donning Agents',
          src: '',
        },
      ],
    }),
  }
</script>
